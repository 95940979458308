import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtService } from './jwt.service';
import { ApiService } from './api.service';
import { LoaderService } from './loader.service';
import { HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, ReplaySubject, Subject, interval, Subscription, of } from 'rxjs';
import { distinctUntilChanged, map, catchError, tap, delay } from 'rxjs/operators';
import { SessionTransaction } from '../models/session-transaction';
import { AuthenticationResponse, User } from '../models/authresponse.model';
import { VaccineContext } from '../models/vaccine-context.model';
import { DataService } from './data.service';
import { FacilityService } from './facility.service';
import { MessageBoxService } from './message-box.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public currentUserSubject = new BehaviorSubject<User>(null);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  public authReponseSubject = new BehaviorSubject<AuthenticationResponse>(null);

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  private timer: Subscription;

  constructor(
    private apiService: ApiService,
    private jwtService: JwtService,
    private router: Router,
    private loaderService: LoaderService,
    private _dataService: DataService,
    private _facilityService: FacilityService,
    private _msgService: MessageBoxService) { }

  private getContextFromAuthResp(resp: AuthenticationResponse): VaccineContext {
    let usertype = '';
    switch (resp.user.userType) {
      case 'ADMIN':
        usertype = 'admin';
        break;
      case 'NF':
        usertype = 'nf';
        break;
      case 'RCF':
        usertype = 'rcf';
        break;
      case 'AAA':
          usertype = 'aaa';
          break;
      case 'PHARMACY':
        usertype = 'pharmacy';
        break;
      case 'ORUSER':
        usertype = 'oruser';
        break;
      case 'R3AP':
        usertype = 'r3ap';
        break;
      case 'ICF':
        usertype = 'icf';
        break;
      case 'DODDADMIN':
        usertype = 'doddAdmin';
        break;
      default:
        console.error(`unknown usertype ${resp.user.userType}`);
        break;
    }

    let context: VaccineContext = {
      viewType: usertype,
      contextUser: resp.user,
      token: resp.token,
      refreshToken: resp.refreshToken,
      sessionId: resp.sessionId
    };
    return context;
  }

  login(cred: { UserName: string, Password: string }) {

    return this.apiService.post('User/Login', cred, false).pipe(map((resp: AuthenticationResponse) => {

      const context = this.getContextFromAuthResp(resp);
      this._dataService.vaccineContext.setToken(context);
      return context;

    }), tap(_ => this.startTokenTimer())
    );

  }

  logOff() {
    this._dataService.vaccineContext.clearContext();
    this.router.navigate(['']);
    this.loaderService.hideLoader();
    if (this.timer != null && !this.timer.closed) {
      this.timer.unsubscribe();
    }

  }

  checkVaccineContext() {

    if (this._dataService.vaccineContext.getContext() == null) {
      this.logOff();
    } else {
      let context = this._dataService.vaccineContext.getContext();
      if (context.contextUser == null) {
        this.logOff();
      }
      switch (context.viewType) {
        case 'admin':
          //this.router.navigate(['vaccine-schedule/admin']);
          this.router.navigate(['facility-admin']);
          break;
        case 'doddAdmin':
          //this.router.navigate(['dodd-admin']);
          //facility-admin
          this.router.navigate(['facility-admin']);
          break;
        case 'r3ap':
          this.router.navigate(['r3ap']);
          break;
        case 'pharmacy':
          if (context.contextPharmacy == null) {
            this.logOff();
          }
          //this.router.navigate(['vaccine-schedule/pharmacy']);
          this.router.navigate(['covid-lite-home']);
          break;
        case 'nf':
          if (context.contextFacility == null) {
            this.logOff();
          }
          this.router.navigate(['facility-home']);
          break;
        case 'aaa':
          if (context.contextFacility == null) {
            this.logOff();
          }
          this.router.navigate(['facility-home']);
          break;
        case 'rcf':
          if (context.contextFacility == null) {
            this.logOff();
          }
          this.router.navigate(['facility-home']);
          break;
        case 'icf':
          if (context.contextFacility == null) {
            //this.logOff();
            console.error('context facility is null, please check this asap!');
          }
          this.router.navigate(['facility-home']);
          break;
        case 'oruser':
            this.router.navigate(['outreach-user']);
        break;
        default:
          console.error('unknown context', context.viewType);
          this.logOff();
          break;

      }
    }

  }

  private checkVaccineContextBeforeLoginSubscription: Subscription;

  checkVaccineContextBeforeLogin() {



    if (this.checkVaccineContextBeforeLoginSubscription == null || (this.checkVaccineContextBeforeLoginSubscription != null && this.checkVaccineContextBeforeLoginSubscription.closed)) {

      this.checkVaccineContextBeforeLoginSubscription = interval(2000).subscribe(() => {

        if (this._dataService.vaccineContext.getContext() == null) {
          return;
        } else {
          let context = this._dataService.vaccineContext.getContext();
          if (context.contextUser == null) {
            return;
          }
          switch (context.viewType) {
            case 'admin':
              this.checkVaccineContextBeforeLoginSubscription.unsubscribe();
              //this.router.navigate(['vaccine-schedule/admin']);
              this.router.navigate(['facility-admin']);
              break;
            case 'pharmacy':
              if (context.contextPharmacy == null) {
                return;
              }
              this.checkVaccineContextBeforeLoginSubscription.unsubscribe();
              //this.router.navigate(['vaccine-schedule/pharmacy']);
              this.router.navigate(['facility-home']);
              break;
            case 'nf':
              if (context.contextFacility == null) {
                return;
              }
              this.checkVaccineContextBeforeLoginSubscription.unsubscribe();
              //this.router.navigate(['vaccine-schedule/facility']);
              this.router.navigate(['facility-home']);
              break;
            case 'rcf':
              if (context.contextFacility == null) {
                return;
              }
              this.checkVaccineContextBeforeLoginSubscription.unsubscribe();
              //this.router.navigate(['vaccine-schedule/facility']);
              this.router.navigate(['facility-home']);
              break;

          }
        }


      });



    }

  }

  watchContextSubscription: Subscription;

  watchContext() {



    if (this.watchContextSubscription == null || (this.watchContextSubscription != null && this.watchContextSubscription.closed)) {

      this.watchContextSubscription = interval(2000).subscribe(() => {
        if (this._dataService.vaccineContext.getContext() == null) {
          this.watchContextSubscription.unsubscribe();
          this.logOff();
        } else {
          let context = this._dataService.vaccineContext.getContext();
          if (context.contextUser == null) {
            this.watchContextSubscription.unsubscribe();
            this.logOff();
          }
          switch (context.viewType) {
            case 'pharmacy':
              if (context.contextPharmacy == null) {
                this.watchContextSubscription.unsubscribe();
                this.logOff();
              }
              break;
            case 'nf':
              if (context.contextFacility == null) {
                this.watchContextSubscription.unsubscribe();
                this.logOff();
              }
              break;
            case 'rcf':
              if (context.contextFacility == null) {
                this.watchContextSubscription.unsubscribe();
                this.logOff();
              }
              break;
            default:
              break;
          }
        }

      });
    }

  }

  getTokenRemainingTime() {
    const accessToken = this._dataService.vaccineContext.getToken();
    if (!accessToken) {
      return 0;
    }
    const jwtToken = JSON.parse(atob(accessToken.split('.')[1]));
    const expires = new Date(jwtToken.exp * 1000);
    return expires.getTime() - Date.now();
  }

  startTokenTimer() {
    const timeout = this.getTokenRemainingTime();
    //console.log('remaing token time', timeout);
    if (this.timer == null || this.timer.closed) {
      this.timer = of(true)
        .pipe(
          delay(timeout - 10000),
          tap(() => this.refreshToken().subscribe())
        )
        .subscribe();
    }

  }

  refreshToken() {
    return this.apiService.post('User/RefershToken', null, false).pipe(
      map((resp: AuthenticationResponse) => {

        const context = this.getContextFromAuthResp(resp);
        this._dataService.vaccineContext.updateToken(context);
        return context;

      }),
      tap(_ => this.startTokenTimer()),
      catchError((err, ob) => {
        console.error('refresh token', err);
        this._msgService.error({
          title: 'Error',
          message: 'Unable to keep the session active'
        }).subscribe(_ => {
          this.logOff();
        });
        return ob;
      }));
  }

  getLastTranTime(): Observable<any> {
    return this.apiService.post(`User/GetLastTransactionTime`, null, false);
  }

  updateLastTransactionTime(): Observable<any> {
    //UpdateLastTransactionTime
    return this.apiService.post(`User/UpdateLastTransactionTime`, null, false);
  }

  getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  chnagepassword(userinfo): Observable<any> {

    return this.apiService.post('User/ChangePasswordasync/changespassword', userinfo)
      .pipe(map(data => {
        return data;
      }));
  }

  checkPassword(userinfo) {
    return this.apiService.post('User/CheckPasswordasync/CheckPassword', userinfo)
      .pipe(map(data => {
        return data;
      }));

  }

  ValidateCurrentPasswordAsync(userinfo) {
    return this.apiService.post('User/ValidateCurrentPassword?username=' + userinfo.username + '&password=' + userinfo.password)
      .pipe(map(data => {
        return data;
      }));
  }



}
