import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { VaccineContext } from '../models/vaccine-context.model';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  _storage = sessionStorage;


  constructor() {

  }

  keys = {
    pharmacy: 'contextPharmacy',
    facility: 'contextFacility',
  }

  contextView: string;

  pharmacy = {

    contextPharmacySet: new Subject<string>(),

    setContext: (id: any) => {
      this._storage.setItem(this.keys.pharmacy, id);
      this.pharmacy.contextPharmacySet.next(id);
      this.contextView = "Pharmacy";
    },

    getContext: (): string => {
      this.contextView = "Pharmacy";
      return this._storage.getItem(this.keys.pharmacy);
    }

  }

  facility = {

    contextFacilitySet: new Subject<string>(),

    setContext: (id: any) => {
      this._storage.setItem(this.keys.facility, id);
      this.facility.contextFacilitySet.next(id);
      this.contextView = "Facility";
    },

    getContext: (): string => {
      this.contextView = "Facility";
      return this._storage.getItem(this.keys.facility);
    }

  }

  encription = {
    key: 'DF#$YTU',
    encript: (val: string): string => {
      return CryptoJS.AES.encrypt(val, this.encription.key).toString()
    },
    decript: (val: string): string => {
      return CryptoJS.AES.decrypt(val, this.encription.key).toString(CryptoJS.enc.Utf8);
    }

  }

  vaccineContext = {

    contextKey: 'U2FsdGVkX1+TIBsxUvCndjDgxGn+QBaEJnIEo8NdH0c=',
    contextLoaded: new Subject<VaccineContext>(),
    contextCleared: new Subject<any>(),

    setContext: (context: VaccineContext) => {

      //const encriptedkey = this.encription.encript(this.vaccineContext.contextKey)
      const encrpteddata = this.encription.encript(JSON.stringify(context));
      this._storage.setItem(this.vaccineContext.contextKey, encrpteddata);

      this.vaccineContext.contextLoaded.next(context);

    },
    getContext: (): VaccineContext => {

      const dencrpteddata = this._storage.getItem(this.vaccineContext.contextKey);
      if (dencrpteddata == null) {
        return null;
      }
      let cntx = this.encription.decript(dencrpteddata);
      if (cntx == null) {
        return null;
      }
      //const context = this.encription.decript(cntx);
      //console.log(context);
      return JSON.parse(cntx);
    },
    clearContext: () => {
      this._storage.clear();
      this.vaccineContext.contextCleared.next(null);
    },
    getToken: (): string => {
      const cntext = this.vaccineContext.getContext();
      if (cntext == null) {
        return null;
      }
      return cntext.token;
    },

    setToken: (context: VaccineContext) => {
      const encrpteddata = this.encription.encript(JSON.stringify(context));
      this._storage.setItem(this.vaccineContext.contextKey, encrpteddata);
    },

    updateToken: (context: VaccineContext) => {
      let _localContext = this.vaccineContext.getContext();
      _localContext.token = context.token;
      _localContext.refreshToken = context.refreshToken;
      this.vaccineContext.setContext(_localContext);
    }

  }


  getSelectedDocument() {
    return sessionStorage.getItem('documentId');
  }

  getSelectedDocumentName() {
    return sessionStorage.getItem('documentName');
  }

  saveSelectedDocument(document) {
    sessionStorage.setItem('documentId', document.dB_ID);
    sessionStorage.setItem('documentName', document.name);
  }

  clearSelectedDocument() {
    if (sessionStorage.getItem('documentId')) {
      sessionStorage.removeItem('documentId');
      sessionStorage.removeItem('documentName');
    }
  }

  clearToken() {
    sessionStorage.removeItem('HENS3');
  }

  setSearchFilter(filter) {
    sessionStorage.setItem('searchFilter', JSON.stringify(filter));
  }

  getSearchFilter(): any {
    return JSON.parse(sessionStorage.getItem('searchFilter'));
  }

  clearSearchFilter() {
    sessionStorage.removeItem('searchFilter');
  }

  saveSearchFilter(filter) {
    sessionStorage.setItem('search-filter', JSON.stringify(filter));
  }

}


