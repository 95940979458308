import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ManageUsersService, AlertsService } from '../../shared';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  userName = new FormControl('', [Validators.required]);
  processing = false;

  constructor(private manageUserService: ManageUsersService,
    private alertsService: AlertsService,
    public router: Router) { }

  ngOnInit(): void {
  }

  onForgotPassword() {
    this.processing = true;
    this.userName.disable();
    let username = this.userName.value.toString().toUpperCase();
    this.manageUserService.resetPassword(username).subscribe(resp => {
      this.userName.enable();
      //console.log(resp);
      this.processing = false;
      if (resp.code == 0) {
        this.alertsService.showSucessMessage("Password has been reset successfully ! <br/> An email has been sent with your new password.", 'Success');
      }
    }, err => {
      this.userName.enable();
      //console.error(err);
      this.processing = false;
      if (err.error) {
        if (err.error.errorCode == 1) {
          this.alertsService.showErrorMessage(`A user with username '${username}' not found !`, 'invaid username');
        }
      }
    });

  }

}
