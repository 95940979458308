import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { UserService, User, AlertsService, DataService, LoaderService, Facilities } from '../../shared';
import { timer, of } from 'rxjs';
import { catchError, debounceTime } from 'rxjs/operators';
import { VaccineContext } from '../../shared/models/vaccine-context.model';
import { FacilityService } from '../../shared/services/facility.service';
import { Facility } from '../../shared/models/facility.model';
import { Pharmacy } from '../../shared/models/pharmacy.models';
import { PharmacyService } from '../../shared/services/pharmacy.service';
import { MessageBoxService } from '../../shared/services/message-box.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  authForm: FormGroup;
  isSubmitting = false;
  formloading = false;
  backToSearch: boolean;

  constructor(
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    private alertsService: AlertsService,
    private _dateService: DataService,
    private _facilityService: FacilityService,
    private _pharmacyService: PharmacyService,
    private _msgService: MessageBoxService,
    private loaderService: LoaderService
  ) {
    this.authForm = this.fb.group({
      UserName: new FormControl('', [Validators.pattern("[A-Za-z0-9\. \'-]+"), Validators.maxLength(60), Validators.required]),
      Password: new FormControl('', [Validators.maxLength(60), Validators.required])
    });

    this.backToSearch = false;
  }

  ngOnInit() {

    this.alertsService.clearLoader();
    /*
      if (localStorage.getItem("HENS3")) {            
          this.router.navigateByUrl('/search');
      } */
    //this.userService.purgeAuthFromLogin();

    //this._dateService.vaccineContext.clearContext();

    this.userService.checkVaccineContextBeforeLogin();

  }


  submitForm() {

    this.authForm.disable();

    this.isSubmitting = true;

    this.userService.login(this.authForm.value).subscribe((context: VaccineContext) => {

      //set context institution
      //set facility
      if (['nf', 'rcf', 'aaa', 'icf'].indexOf(context.viewType) >= 0) {
        this._facilityService.selectFacility(context.contextUser.institutionId).subscribe(fac => {
          if (fac != null) {
            context.contextFacility = fac;
            this.loaderService.showLoader();
          } else {
            this._msgService.error({
              title: "Error",
              message: "No facility found associated with this account."
            });
            return;
          }
          this._dateService.vaccineContext.setContext(context);
          switch (context.viewType) {
            case 'icf':
              this.router.navigate(['facility-home']);
              break;
            default:
              this.router.navigate(['facility-home']);
          }
          this.isSubmitting = false;
        });
      }

      //dodd admin
      if (context.viewType == 'doddAdmin') {
        this._dateService.vaccineContext.setContext(context);
        this.isSubmitting = false;
        this.loaderService.showLoader();
        // this.router.navigate(['vaccine-schedule/admin']);
        //this.router.navigate(['dodd-admin']);
        this.router.navigate(['facility-admin']);
      }

      //set pharmacy
      if (context.viewType == 'pharmacy') {
        this._pharmacyService.getPharmacy(context.contextUser.institutionId).subscribe(ph => {
          if (ph != null) {
            context.contextPharmacy = ph;
            this._dateService.vaccineContext.setContext(context);
            this.loaderService.showLoader();
            this.router.navigate(['covid-lite-home']);
          } else {

          }
          this.isSubmitting = false;
        });
      }

      //admin
      if (context.viewType == 'admin') {
        this._dateService.vaccineContext.setContext(context);
        this.isSubmitting = false;
        this.loaderService.showLoader();
       // this.router.navigate(['vaccine-schedule/admin']);
        this.router.navigate(['facility-admin']);
      }
      //admin
      if (context.viewType == 'r3ap') {
        this._dateService.vaccineContext.setContext(context);
        this.isSubmitting = false;
        this.loaderService.showLoader();
        // this.router.navigate(['vaccine-schedule/admin']);
        this.router.navigate(['r3ap']);
      }

      if (context.viewType == 'oruser') {
          this._dateService.vaccineContext.setContext(context);
          this.isSubmitting = false;
          this.loaderService.showLoader();
          this.router.navigate(['outreach-user']);
      }

    },

      resp => {
      if (resp.error && resp.error.errorcode) {
       
        this._msgService.error({
          title: 'Error',
          message: resp.error.error
        })
      }
        console.error(resp);
        this.isSubmitting = false;
      },

      () => {
        this.isSubmitting = false;

    });

    //this.isSubmitting = false;
    this.authForm.enable();
  }



  async loginAsFacility() {

    this._dateService.vaccineContext.clearContext();

    const facility = await this._facilityService.selectFacility(101438).toPromise<Facilities>()
    let context: VaccineContext = {
      viewType: 'facility',
      contextFacility: facility,
      //contextUser: {
      //  email: 'facilty@wer.com',
      //  firstName: 'robert',
      //  lastName: 'dsuza',
      //  institutionId: '232323',
      //  userName: 'rdsuze',
      //  userType: 'facilityuser'
      //}
    };

    this._dateService.vaccineContext.setContext(context);

    //this.router.navigate(['vaccine-schedule/facility']);
    this.router.navigate(['covid-lite-home']);

  }

  async loginAsPharmacy() {

    this._dateService.vaccineContext.clearContext();
    const pharmacy = await this._pharmacyService.getPharmacy(101).toPromise<Pharmacy>();
    let context: VaccineContext = {
      viewType: 'pharmacy',
      contextPharmacy: pharmacy,
      //contextUser: {
      //  email: 'pharmacy@wer.com',
      //  firstName: 'julia',
      //  lastName: 'robers',
      //  institutionId: '232323',
      //  userName: 'jroberts',
      //  userType: 'pharmacyUser'
      //}
    };

    this._dateService.vaccineContext.setContext(context);
    //'covid-lite-home'
    //this.router.navigate(['vaccine-schedule/pharmacy']);
    this.router.navigate(['covid-lite-home']);

  }

  async LoginAsAdmin() {

    this._dateService.vaccineContext.clearContext();
    let context: VaccineContext = {
      viewType: 'admin',
      //contextUser: {
      //  email: 'admin@oda.com',
      //  firstName: 'will',
      //  lastName: 'smith',
      //  institutionId: '232323',
      //  userName: 'wsmith',
      //  userType: 'adminUser'
      //}
    };

    this._dateService.vaccineContext.setContext(context);

    //this.router.navigate(['vaccine-schedule/admin']);
    this.router.navigate(['covid-lite-home']);

  }

}
